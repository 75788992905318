<template>
  <el-row class="table">
    <el-tabs v-model="activeName">
      <el-tab-pane
          v-for="item in tabMapOptions"
          :key="item.key"
          :label="item.label"
          :name="item.key"
        >
          <keep-alive>
            <tab-pane :type="activeName" ref="tabPane" v-if="activeName==item.key"/>
          </keep-alive>
      </el-tab-pane>
    </el-tabs>
  </el-row>
</template>

<script>
  import TabPane from './components/TabPane'
  import { syncRefreshList } from '@/api/senate/class'
  export default {
    name: 'senate',
    components: { TabPane },
    watch: {
      activeName(val) {
        this.$router.push(`${this.$route.path}?tab=${val}`)
      },
    },
    created() {
      const tab = this.$route.query.tab
      if (tab) {
        this.activeName = tab
      }
      let perms = JSON.parse(localStorage.getItem('perms'))
      perms.findIndex(list => {
        if (list === 'admin:all') {
          this.tabMapOptions = [
            {
              label: '全部班级',
              key: 'allClass',
            },
            {
              label: '管辖班级',
              key: 'governClass',
            },
          ]
        } else {
          if (list === 'class:all') {
            this.tabMapOptions.push({
              label: '全部班级',
              key: 'allClass',
            })
          } else if (list === 'class:campus') {
            this.tabMapOptions.push({
              label: '管辖班级',
              key: 'governClass',
            })
          } else if (list === 'class:teacher') {
            this.tabMapOptions.push({
              label: '在教班级',
              key: 'teachClass'
            })
          }
        }
      })
      this.activeName = this.tabMapOptions[0].key
    },
    data() {
      return {
        activeName: '',
        tabMapOptions: [],
      }
    },
    methods: {
      // 同步班级列表
      async syncRefreshList() {
        let nowDate = new Date()
        const res = await syncRefreshList({
          minUpdateTime: formatDateYMD(nowDate),
        })
        this.$refs.tabPane.search()
      },
    },
  }
</script>

<style lang="scss" scoped>
@import '~@/style/table.scss';
</style>
