<template>
  <div>
    <el-form :inline="true">
      <div class="row-bg">
        <el-row :gutter="24">
          <el-col :lg="6" :md="24">
            <el-form-item label="所属校区：">
              <el-select
                v-model="queryParam.campus_id"
                class="w100"
                placeholder="请选择"
                clearable
                filterable
                multiple
                collapse-tags
              >
                <el-option
                  v-for="item in schoolArray"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="6" :md="24">
            <el-form-item label="班级名称：">
              <el-input
                v-model="queryParam.className"
                @keyup.enter.native="search"
                placeholder="请输入"
                clearable
                class="w100"
              />
            </el-form-item>
          </el-col>
          <el-col :lg="6" :md="24">
            <el-form-item label="是否结业：">
              <el-select
                v-model="queryParam.isFinish"
                class="w100"
                placeholder="请选择"
                filterable
              >
                <el-option
                  v-for="item in stateArray"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <template v-if="advanced">
            <el-col :lg="6" :md="24">
              <el-form-item label="审核状态：">
                <el-select
                  v-model="queryParam.status"
                  class="w100"
                  placeholder="请选择"
                  filterable
                >
                  <el-option
                    v-for="item in statusArray"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="6" :md="24">
              <el-form-item label="开班日期：">
                  <date-picker ref="dataPicker" :dateTime="dateTime" class="w100" @updateView="datetTimeGet"></date-picker>
              </el-form-item>
            </el-col>
            <el-col :lg="6" :md="24">
              <el-form-item label="课程名称：">
                <el-input
                  v-model="queryParam.courseName"
                  @keyup.enter.native="search"
                  placeholder="请输入"
                  clearable
                  class="w100"
                />
              </el-form-item>
            </el-col>
            <el-col :lg="6" :md="24">
              <el-form-item label="收费项目：">
                <el-input
                  v-model="queryParam.productName"
                  @keyup.enter.native="search"
                  placeholder="请输入"
                  clearable
                  class="w100"
                />
              </el-form-item>
            </el-col>
          </template>
          <search-query
            @updateView="updateView"
            @clearQuery="clearQuery"
            @searchQuery="search"
          ></search-query>
        </el-row>
      </div>
    </el-form>

    <!-- 表格 -->
    <el-card shadow="never">
      <div slot="header" class="flex" style="align-items: center;">
        <span>班级数据</span>
        <div class="flex_1"></div>
        <el-button type="primary" size="mini" @click="addClass()"
          >新增班级</el-button
        >
        <el-button plain size="mini" @click="addOrdinaryClass()"
          >新增普通班级</el-button
        >
        <el-button
          plain
          size="mini"
          v-has="'class:syncClassList'"
          @click="syncRefreshList"
          >同步班级列表</el-button
        >
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>

      <el-table-bar fixed :static="true">
        <el-table
          :key="tableKey"
          :data="dataList"
          fit
          :highlight-current-row="true"
          @sort-change="tableSortAll"
          :default-sort="{ prop: 'open_date', order: 'descending' }"
        >
          <el-table-column
            v-for="item of defaultHeader"
            :key="item.label"
            :label="item.label"
            :align="item.align ? item.align : 'left'"
            :min-width="item.width ? item.width : 'auto'"
            :sortable="item.sortable ? item.sortable : false"
            :prop="item.prop"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <template v-if="item.label === '收费课程'">
                <span v-if="scope.row.productName">{{
                  scope.row.productName
                }}</span>
                <span v-else>--</span>
              </template>
              <template v-else-if="item.label === '班级名称'">
                <span class="column-link" @click="classManage(scope.row)">{{
                  scope.row.name
                }}</span>
              </template>
              <template v-else-if="item.label === '授课课程'">
                <span v-if="scope.row.courseName">{{
                  scope.row.courseName
                }}</span>
                <span v-else>--</span>
              </template>
              <template v-else-if="item.label === '上课进度'">
                <span
                  >{{ Number(scope.row.finished) }}/{{ scope.row.total }}</span
                >
              </template>
              <template v-else-if="item.label === '最近排课日期'">
                <span v-if="scope.row.start_time && scope.row.end_time">
                  {{ scope.row.start_time | formatDateStart
                  }}{{ scope.row.end_time | formatDateEnd }}
                </span>
                <span v-else>--</span>
              </template>
              <template v-else-if="item.label === '收费项目'">
                <span v-if="scope.row.productName">
                  {{ scope.row.productName }}
                </span>
                <span v-else>--</span>
              </template>
              <template v-else-if="item.label === '课程名称'">
                <span v-if="scope.row.courseName">
                  {{ scope.row.courseName }}
                </span>
                <span v-else>--</span>
              </template>
              <template v-else-if="item.label === '状态'">
                <span
                  class="span-state"
                  :style="
                    'backgroundColor' + ':' + getStateColor(scope.row.status)
                  "
                ></span>
                <span v-text="scope.row.status"></span>
              </template>
              <template v-else-if="item.label === '结业日期'">
                <span v-if="!scope.row.finished_date || scope.row.finished_date === '0001-01-01' || scope.row.finished_date === '1900-01-01'">未结业</span>
                <span v-else>{{scope.row.finished_date}}</span>
              </template>
              <template v-else-if="item.label === '操作'">
                <el-button type="text" @click="classManage(scope.row)"
                  >管理</el-button
                >
                <el-divider direction="vertical"></el-divider>
                <el-dropdown trigger="click">
                  <span class="el-dropdown-link">
                    更多<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      @click.native="delClass(scope.row)"
                      :disabled="
                        (scope.row.finished_date !== null &&
                          scope.row.finished_date !== '0001-01-01' && scope.row.finished_date !== '1900-01-01') ||
                          scope.row.status === '已启用'
                      "
                      ><i class="el-icon-delete"></i>删除</el-dropdown-item
                    >
                    <el-dropdown-item v-has="'class:finishClass'" v-if = "scope.row.finished_date === null || scope.row.finished_date === '0001-01-01'  || scope.row.finished_date === '1900-01-01'" @click.native="graduate(scope.row)"
                      ><i class="el-icon-video-pause"></i>结业</el-dropdown-item
                    >
                    <el-dropdown-item v-has="'class:finishClass'" v-else @click.native="ungraduate(scope.row)"><i class="el-icon-video-pause"></i>取消结业</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-table-bar>

      <el-pagination
        class="fr-pagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :total="pagination.total"
      ></el-pagination>
    </el-card>
    <!-- add-class -->
    <add-class
      :show="classDialog"
      :ordinaryClass="ordinaryClass"
      :schoolArray="schoolArray"
      @close="
        classDialog = false
        ordinaryClass = false
      "
      @addClassForm="addClassForm"
    ></add-class>
  </div>
</template>

<script>
  import TextButton from '@/components/TextButton'
  import SearchQuery from '@/components/SearchQuery'
  import AddClass from '@/components/senate/add-class'
  import getMyCampus from '@/mixins/getMyCampus'
  import getStateColor from '@/mixins/getStateColor'
  import { syncRefreshList } from '@/api/senate/class'
  import { formatDateYMD } from '@/utils/formatDate'
  import datePicker from '@/components/date-picker'
  import {
    getClassAll,
    getClassListCampus,
    delClass,
    getLessonInfoByClassId,
    classFinish,
    getProgress,
    addClassData,
    classnoFinish,
    getClassListTeacher
  } from '@/api/senate/class'
  // 默认展示列
  const baseColSetting = [
    {
      label: '校区',
      prop: 'campusName',
      state: true,
      align: 'center',
      width: '130',
    },
    {
      label: '收费项目',
      prop: 'productName',
      state: true,
      align: 'center',
      width: '120',
    },
    {
      label: '班级名称',
      prop: 'name',
      state: true,
      align: 'left',
      sortable: true,
      width: '180',
    },
    {
      label: '开班日期',
      prop: 'open_date',
      state: true,
      sortable: true,
      align: 'center',
      width: '150',
    },
    {
      label: '上课进度',
      prop: '',
      state: true,
      align: 'center',
      width: '100',
    },
    {
      label: '最近排课日期',
      prop: '',
      state: true,
      sortable: true,
      align: 'center',
      width: '200',
    },
    {
      label: '课程名称',
      prop: 'courseName',
      state: true,
      align: 'center',
      width: '150',
    },
    {
      label: '状态',
      prop: 'status',
      state: true,
      align: 'center',
      width: '100',
    },
    {
      label: '结业日期',
      prop: 'finished_date',
      state: true,
      align: 'center',
      width: '100',
    },
    {
      label: '来源',
      prop: 'source',
      state: false,
      align: 'center',
      width: '100',
    },
    {
      label: '操作',
      state: true,
      align: 'center',
      width: '130',
    },
  ]
  export default {
    components: { TextButton, SearchQuery, AddClass, datePicker },
    props: {
      type: {
        default: '',
        type: String,
        require: true,
      },
    },
    watch: {
      type(val) {
        this.switchClass(val)
      },
    },
    mixins: [getMyCampus, getStateColor],
    mounted() {
      this.switchClass(this.type)
    },
    computed: {
      defaultHeader() {
        return this.colSetting.filter(item => item.state)
      },
    },
    data() {
      return {
        dataList: [],
        dateTime: [],
        tableKey: 1,
        queryParam: {
          // 查询参数
          isFinish: null,
          campus_id: [],
          status: null
        },
        colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
        baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
        pagination: {
          currentPage: 1,
          pageSize: 10,
          pageSizes: [10, 20, 30, 40],
          layout: 'total, sizes, prev, pager, next, jumper',
          total: 0,
        },
        advanced: false,
        statusArray: [
          {
            value: null,
            label: '全部'
          },
          {
            value: '已启用',
            label: '已启用',
          },
          {
            value: '待审核',
            label: '待审核',
          },
        ],
        stateArray: [
          {
            value: null,
            label: '全部'
          },
          {
            value: false,
            label: '未结业',
          },
          {
            value: true,
            label: '已结业',
          },
        ],
        classOrderBy: 'openDate', // 排序依据
        classIsAsc: false, // 默认为升序
        classDialog: false,
        ordinaryClass: false, // 是否未普通（收费）班级
      }
    },
    methods: {
      datetTimeGet(data) {
        if(data === null) {
          this.minStartTime = ''
          this.maxStartTime =  ''
        }else {
          this.minStartTime = data[0]
          this.maxStartTime =  data[1]
        }
      },

      // 清空选项
      clearQuery() {
        this.queryParam = {
          isFinish: null,
          status: null
        };
        this.$refs.dataPicker.time = []
        this.maxStartTime = ''
        this.minStartTime = ''
      },

      switchClass(type) {
        switch (type) {
          case 'allClass':
            return this.getClassAll()
          case 'governClass':
            return this.getClassListCampus()
          case 'teachClass':
            return this.getClassListTeacher()
        }
      },

      // 同步班级列表
      async syncRefreshList() {
        let nowDate = new Date()
        await syncRefreshList({
          minUpdateTime: formatDateYMD(nowDate),
        })
        window.$msg('同步成功')
        this.switchClass(this.type)
      },

      search() {
        this.pagination.currentPage = 1
        this.switchClass(this.type)
      },

      // 获取全部班级列表
      async getClassAll() {
        this.dataList = []
        const res = await getClassAll({
          pageNum: this.pagination.currentPage,
          pageSize: this.pagination.pageSize,
          needCount: true,
          campusIdSet: this.queryParam.campus_id,
          className: this.queryParam.className,
          orderBy: this.classOrderBy,
          isAsc: this.classIsAsc,
          isFinish: this.queryParam.isFinish,
          productName: this.queryParam.productName,
          courseName: this.queryParam.courseName,
          minOpenDate: this.minStartTime ? this.minStartTime + ' 00:00:00' : null,
          maxOpenDate: this.maxStartTime ? this.maxStartTime + ' 23:59:59' : null,
          status: this.queryParam.status ? this.queryParam.status : null,
        })
        const dataList = res.body.list
        dataList.forEach((item, index) => {
          item.finished = ''
          item.total = ''
          this.getProgress(item.class_id, index)
        })
        this.dataList = dataList
        this.pagination.total = res.body.total
      },

      // 获取管辖班级列表
      async getClassListCampus() {
        this.dataList = []
        const res = await getClassListCampus({
          pageNum: this.pagination.currentPage,
          pageSize: this.pagination.pageSize,
          needCount: true,
          campusIdSet: this.queryParam.campus_id,
          className: this.queryParam.className,
          orderBy: this.classOrderBy,
          isAsc: this.classIsAsc,
          isFinish: this.queryParam.isFinish,
          productName: this.queryParam.productName,
          courseName: this.queryParam.courseName,
          minOpenDate: this.minStartTime ? this.minStartTime + ' 00:00:00' : null,
          maxOpenDate: this.maxStartTime ? this.maxStartTime + ' 23:59:59' : null,
          status: this.queryParam.status ? this.queryParam.status : null,
        })
        const dataList = res.body.list
        dataList.forEach((item, index) => {
          item.finished = ''
          item.total = ''
          this.getProgress(item.class_id, index)
        })
        this.dataList = dataList
        this.pagination.total = res.body.total
      },

      // 获取在教班级列表
      async getClassListTeacher () {
        this.dataList = []
        const res = await getClassListTeacher({
          pageNum: this.pagination.currentPage,
          pageSize: this.pagination.pageSize,
          needCount: true,
          campusIdSet: this.schoolValue,
          className: this.classNameValue,
          orderBy: this.classOrderBy,
          isAsc: this.classIsAsc,
          isFinish: this.stateValue,
          productName: this.productName,
          courseName: this.courseValue,
          minOpenDate: this.minStartTime ? this.minStartTime + ' 00:00:00' : null,
          maxOpenDate: this.maxStartTime ? this.maxStartTime + ' 23:59:59' : null,
          status: this.queryParam.status ? this.queryParam.status : null,
        })
        const dataList = res.body.list
        dataList.forEach((item, index) => {
          item.finished = ''
          item.total = ''
          this.getProgress(item.class_id, index)
        })
        this.dataList = dataList
        this.pagination.total = res.body.total
      },

      // 获取班级上课进度
      getProgress(id, idx) {
        getProgress({
          classId: id,
        }).then(res => {
          this.dataList[idx].finished = res.body.finished
          this.dataList[idx].total = res.body.total
        })
      },

      // 班级列表排序
      tableSortAll(column) {
        switch (column.prop) {
          case 'name':
            this.classOrderBy = 'className'
            break
          case 'open_date':
            this.classOrderBy = 'openDate'
            break
          case 'courseName':
            this.classOrderBy = 'courseName'
            break
          case 'lately_lesson':
            this.classOrderBy = 'nextLessonStartTime'
            break
        }
        switch (column.order) {
          case 'ascending':
            this.classIsAsc = true
            break
          case 'descending':
            this.classIsAsc = false
        }
        this.switchClass(this.type)
      },

      // 管理班级
      classManage(row) {
        if (!row.productId) {
          this.$router.push({
            path: '/senate/class-detail',
            query: {
              classID: row.class_id,
              courseId: row.courseId,
            },
          })
        } else {
          this.$router.push({
            path: '/senate/class-detail',
            query: {
              classID: row.class_id,
              courseId: row.courseId,
              productId: row.productId,
            },
          })
        }
      },

      // 删除班级
      delClass(row) {
        this.$confirm('确定删除当前班级吗？', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            await delClass({
              id: row.class_id,
            })
            window.$msg('删除成功')
            this.switchClass(this.type)
          })
          .catch(() => {
            return false
          })
      },

      // 班级结业
      graduate(row) {
        getLessonInfoByClassId({
          class_id: row.class_id,
          status: '未上课',
        })
          .then(res => {
            if (res.state === 'success') {
              if (res.body.list.length !== 0) {
                this.$confirm('还有未上课的排课，是否确认结业？', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning',
                }).then(() => {
                  this.classFinish(row.class_id)
                })
              } else {
                this.$confirm('是否确认结业？', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning',
                }).then(() => {
                  this.classFinish(row.class_id)
                })
              }
            }
          })
          .catch(() => {
            return false
          })
      },

      ungraduate(row) {
        this.$confirm('是否确认修改为未结业？', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.classnoFinish(row.class_id)
        })
      },

      // 变为未结业
      async classnoFinish(classId) {
        await classnoFinish({
          classId,
        })
        window.$msg('修改状态成功')
        this.queryParam.isFinish = false
        this.switchClass(this.type)
      },

      // 结课
      async classFinish(classId) {
        await classFinish({
          classId,
        })
        window.$msg('结课成功')
        this.switchClass(this.type)
      },

      // 新增班级
      addClass() {
        this.classDialog = true
      },
      // 新增普通（收费）班级
      addOrdinaryClass() {
        this.ordinaryClass = true
        this.classDialog = true
      },
      addClassForm(data) {
        let addClass = {
          name: data.name,
          campus_id: data.campus_id,
          course_id: data.catalogId
            ? data.catalogId[data.catalogId.length - 1]
            : null,
          open_date: data.open_date,
          lctype:data.lctype,
          type:data.type,
          teacherIds: data.teacherIds,
          assistantIds: data.assistantIds ? data.assistantIds : null,
          product_id: data.product_id ? data.product_id : null,
          classroom_id: data.classroom_id ? data.classroom_id[0] : null,
        }
        addClassData({ ...addClass }).then(res => {
          if (res.state === 'success') {
            window.$msg('新增成功')
            this.search()
          }
        })
      },

      updateView(data) {
        this.advanced = data
      },

      update(data) {
        this.tableKey = this.tableKey + 1
        if (data.lenght !== 0) {
          this.colSetting.forEach(item => {
            item.state = false
            data.forEach(ele => {
              if (ele === item.label && !item.state) {
                item.state = true
              }
            })
          })
        } else {
          this.colSetting = []
        }
      },

      // 分页
      handleCurrentChange(val) {
        this.pagination.currentPage = val
        this.switchClass(this.type)
      },
      handleSizeChange(val) {
        this.pagination.pageSize = val
        this.switchClass(this.type)
      },
    },
  }
</script>

<style lang="scss" scoped>
@import '../../../style/container.scss';

/deep/ .el-form--inline .el-form-item {
  margin-right: 0;
}
</style>
